<template>
<div>
  <CCard>
    <CCardHeader>
      <h4 style="color:#00A8FF">{{ device.name }}({{ serviceModel }})</h4>
    </CCardHeader>

    <CCardBody>
        <h5 style="margin-top: 20px; text-align: right;">{{measureDate}}</h5>
        <div class="deviceInfoBox">
          <div class="btnBox">
            <button disabled="disabled" style="cursor: default">{{device_type.in}}</button>
          </div>
          <table>
            <tr>
              <th>유량</th>
              <td>{{flowRateIn}}</td>
            </tr>
            <tr>
              <th>부유물질</th>
              <td>{{suspendedSolidIn}}</td>
            </tr>
            <tr>
              <th>수위</th>
              <td>{{waterLevelIn}}</td>
            </tr>
          </table>
          <br>
          <div class="btnBox">
            <button disabled="disabled" style="cursor: default">강우 데이터</button>
          </div>
          <table>
              <tr>
                <th>강우량</th>
                <td>{{rainGauge}}</td>
              </tr>                                           
          </table>
        </div>
 
        <div class="deviceSlideBox">
          <img :src="device_image" alt="기기 이미지" style="max-width: 100%; height: 100%;">
        </div>

        <div class="deviceInfoBox">
          <div class="btnBox">
            <button disabled="disabled" style="cursor: default">{{device_type.out}}</button>
          </div>
          <table>
            <tr>
              <th>유량</th>
              <td>{{flowRateOut}}</td>
            </tr>
            <tr>
              <th>부유물질</th>
              <td>{{suspendedSolidOut}}</td>
            </tr>
            <tr>
              <th>수위</th>
              <td>{{waterLevelOut}}</td>
            </tr>
          </table>
        </div>

        <div class="deviceInfoBox">
        <div class="btnBox">
          <button disabled="disabled" style="cursor: default">기기 작동상태</button>
        </div>
        <table>
          <tr>
            <th>정체수배수</th>
            <td>{{runningStagStatus}}</td>
          </tr>
          <tr>
            <th>공기세척</th>
            <td>{{runningAirStatus}}</td>
          </tr>
          <tr>
            <th>역세척폐수배출</th>
            <td>{{runningBackStatus}}</td>
          </tr>
          <tr>
            <th>처리수이송</th>
            <td>{{runningTreatStatus}}</td>
          </tr>                                            
        </table>
      </div>

    </CCardBody>
    <CCardFooter col="12" xl="12">
      <!--<h5 class="float-left">{{ plcPreWaterLevel }}  {{ plcFilterWaterLevel }}  {{ plcTreatWaterLevel }}</h5>-->
      <CButton color="danger" class="float-right" v-show="is_admin == true" @click="resetSensorBoard()">센서보드 리셋</CButton>
      <CButton color="info" class="float-right mr-1" v-show="is_admin == true" @click="sensorMappingSetting()">수위센서 설정</CButton>
      <CButton color="success" class="float-right mr-1" @click="autoPicSetting()">자동 촬영</CButton>
      <CButton color="warning" class="float-right mr-1" @click="openDialog('sensor')">센서 정보 관리</CButton>
    </CCardFooter>
  </CCard>

  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
  <DeviceInfo
      ref="deviceInfo"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :isPeriodic="false"
      :device.sync="device"
      @hide="hideModal"
      @update="onUpdated"
  />
  <AutoCarmeraSet
      ref="autoCarmeraSet"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :isPeriodic="false"
      :device.sync="device"
      @hide="hideModal"
      @update="onUpdated"
  />
  <SensorMappingSet
    ref="sensorMappingSet"
    :isAdmin="is_admin"
    :isMobile="is_mobile"
    :isPeriodic="false"
    :device.sync="device"
    @hide="hideModal"
    @update="onUpdated"
  />
</div>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import EventBus from '@/services/EventBus'
import utils from '@/services/utils';

import DeviceInfo from '@/views/device/DeviceInfo'
import AutoCarmeraSet from '@/views/btfs/AutoCameraSet'
import SensorMappingSet from '@/views/btfs/SensorMappingSet'

const DEFAULT_SERVICE_TYPE = 'sis'

const DEVICE_TYPES = [
  {in:'유입부', out:'유출부'},
  {in:'여과조', out:'처리조'},
];

const NO_MEASURE_COMENT = '측정값 없음'
const WATER_LEVEL = 19;
const PRECIPITATION = 22;
const FLOW_RATE = 24;
const SUSPENDED_SOLID = 25;
const PLC_WATER_LEVEL = 29;
const RUNNING_STATUS = 34;

export default {
  name: 'Device',
  components: {
    DeviceInfo,
    AutoCarmeraSet,
    SensorMappingSet
  },
  props: {
    device_guid: {
      type: String,
      default: ''
    },
    device: {
      type: Object
    }
  },
  created: function() {
    this.user_info = this.$store.state.auth.user_info;
    this.user = this.user_info.user
    this.sites = this.user_info.sites

    utils.loadThresholds(res => this.thresholds = res)

    var image_height = 100;
    this.image_style = {
      'width': '100%',
      'min-height': image_height + 'px',
      'max-height': image_height + 'px'
    }

    EventBus.$on('alarmData', this.onEvent);
    EventBus.$on('reload', this.reloadListener);
  },
  beforeDestroy(){
    EventBus.$off('alarmData', this.onEvent);
    EventBus.$off('reload', this.reloadListener)
  },
  data () {
    return {
      site: {
        name: 'tech9'
      },
      sensor: {},
      deviceImages: [],
      device_image: '',
      device_type: DEVICE_TYPES[0],
      isBackwashing: true,
      image_style: {},
      thresholds: [],
      is_air_prediction: false,
      is_admin: this.$store.state.auth.is_admin,
      is_mobile: this.$is_mobile,
      storage: "storages",
      serviceModel: '',
      sensor_type: utils.getSensorTypes(),
    }
  },
  watch: {
    device: function(new_val, old_val) {
      console.log('Device::watch device[guid] - ', new_val.guid)
      new_val.install_date = new_val.install_date || new Date(moment('2017-06-21').utc());
      this.getSensors();
      this.getImages();
    }
  },
  computed: {
    measureDate() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return moment(this.device.sensor.created_at).format('측정일 : YYYY년 MM월 DD일 HH시 mm분 ss초')
    },
    rainGauge() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(PRECIPITATION, 1)
    },    
    flowRateIn() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(FLOW_RATE, 1)
    },
    flowRateOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(FLOW_RATE, 2)
    },
    suspendedSolidIn() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(SUSPENDED_SOLID, 1)
    },
    suspendedSolidOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(SUSPENDED_SOLID, 2)
    },
    waterLevelIn() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(WATER_LEVEL, 1)
    },
    waterLevelOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(WATER_LEVEL, 2)
    },
    runningStagStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 1)
    },
    runningAirStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 2)
    },
    runningBackStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 3)
    },
    runningTreatStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 4)
    }
  },
  methods: {
    reloadListener() {
      console.log('Device::reloadListener')
      this.getDataControl()
      this.getSensors();
      this.getImages();
    },
    getValue(type, id) {
      var item = _.find(this.device.sensor.data, {type:type, id:id })
      if (item) {
        var stype = _.find(this.sensor_type, {code:item.type, id:item.id})
        if (!stype) stype = _.find(this.sensor_type, {code:item.type})
        if (stype) {
          if(item.type == FLOW_RATE)
          {
            if(item.id == 1)
            {
              var flow_r2 = 0;
              if(!_.isUndefined(_.find(this.device.sensor.data, {type:24, id:3 }))){
                var flow_ = _.find(this.device.sensor.data, {type:24, id:3 })
                flow_r2 = flow_.value;
              }
              return (item.value + flow_r2).toFixed(2) + stype.unit;
            }
            else if(item.id == 2)
            {
              var flow_r2 = 0;
              if(!_.isUndefined(_.find(this.device.sensor.data, {type:24, id:4 }))){
                var flow_ = _.find(this.device.sensor.data, {type:24, id:4 })
                flow_r2 = flow_.value;  
              }
              return (item.value + flow_r2).toFixed(2) + stype.unit;
            }
          }
          else if(item.type == PLC_WATER_LEVEL){
            if(item.id == 1)
              return item.value == 1 ? "전처리수조 레벨: H" : "전처리수조 레벨: L";
            else if(item.id == 2)
              return item.value == 1 ? "여과조수조 레벨: H" : "여과조수조 레벨: L";              
            else if(item.id == 3)
              return item.value == 1 ? "처리조수조 레벨: H" : "처리조수조 레벨: L";                            
          }
          else if(item.type == RUNNING_STATUS){
            if(item.id == 1)
              return item.value == 1 ? "가동중" : "정지";
            else if(item.id == 2)
              return item.value == 1 ? "가동중" : "정지";              
            else if(item.id == 3)
              return item.value == 1 ? "가동중" : "정지";     
            else if(item.id == 4)
              return item.value == 1 ? "가동중" : "정지";                            
          }          
          return item.value.toFixed(2) + stype.unit
        } else {
          return NO_MEASURE_COMENT
        }
      } else {
        return NO_MEASURE_COMENT
      }
    },
    onEvent(evt_message) {
      for (var i = 0; i < evt_message.message.datas.length; i++) {
        var elem = evt_message.message.datas[i];
        if (this.device_guid === elem.data.device_guid) {
          console.log("Device::onEvent : this.device_guid[%s], data.device_guid[%s]", this.device_guid, elem.data.device_guid);
          this.getSensors();
          this.getImages();
          return
        }
      }
    },
    openUploadDialog() {
    },
    onUpdated(event) {
      // updated event must be delivered to parent because "device" is [props]
      this.$emit('update', event)
    },
    resetSensorBoard() {
      var message = "센서보드 : " + this.device.name + " 를 리셋 하시겠습니까?"
      this.$refs.confirmDialog.show(message, '센서보드 리셋', undefined, 'resetBoard');
    },
    autoPicSetting() {
      this.$refs.autoCarmeraSet.show(this.device.guid, this.device.name);
    },
    sensorMappingSetting() {
      this.$refs.sensorMappingSet.show(this.device.guid, this.device.name, this.device.standard, );
    },
    hideModal(event) {
      if (event.name === 'Uploader' && event.which === 'update') {
        if (event.data.need_refresh)
          this.getImages();
        return
      }
      if(event.which === 'resetBoard'){
          var data = {
          command: 'exec',
          device_guid: this.device.guid,
          params: [{
            cmd: 'reset',
            type: 'reset',
            data: 'now',
            site_guid: this.device.site_guid
          }]
        }
        this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
          .then(res => {
            this.showModal = false;
          })
          .catch(err => {
            console.log('WashingDialog execute error: ', err.toString())
            this.$refs.notifyDialog.show(err.toString())
            setTimeout(function() {
              self.showModal = false;
            }, 500)
          })  
      }
      else if (event.data !== 'confirm' || event.which !== 'resetBoard' || event.which !== 'autoPic') {
        return; // cancel
      }
    },
    openDialog(which) {
      var info = {
        device: this.device,
        site: this.site,
        service_type: DEFAULT_SERVICE_TYPE,
        thresholds: _.cloneDeep(_.filter(this.thresholds, o => o.service_type === DEFAULT_SERVICE_TYPE))
      }
      switch (which) {
        case 'sensor':
          this.$refs.deviceInfo.show(_.isEmpty(this.sensor) ? [] : this.sensor);
          break;
      }
    },
    getThresholdName(name) {
      var result = "";
      if (typeof name === "undefined")
        return result;
      var new_name = name.split("-").map(function(val) {
        return val
      });
      result = new_name && new_name.length > 1 ? new_name[1] : new_name[0];
      return result;
    },
    getDataControl() {
      var self = this;
      var service_type = _.get(this.device, "service_type"); // || submerged
      switch (service_type) {
        case 'filtered': this.serviceModel = '여과형'; break;
        default: this.serviceModel = '침지형'
      }
      this.isBackwashing = service_type == 'filtered';
      if (this.isBackwashing) {
        this.device_image = this.device.default_image;
        this.device_type = DEVICE_TYPES[0];
      } else {
        this.device_image = 'images/btfs_v2.jpg';
        this.device_type = DEVICE_TYPES[1];
      }
    },
    getSensors() {
      var filter = {
        where: {
          device_guid: this.device_guid
        },
        limit: 1,
        order: 'created_at desc'
      }
      this.$store.dispatch('rest/find', {model:'sensors',filter:filter})
        .then((res) => {
          var filter = {
            guid:this.device.site_guid
          }
          this.site = _.find(this.sites, filter)
          this.sensor = _.isEmpty(res) ? res : _.first(res)
          this.device.sensor = this.sensor;
          this.getDataControl()
        })
        .catch(err => console.log(err.toString()))
    },
    getImages() {
      var self = this;

      this.is_air_prediction = this.device.network_module.model == "대기정보";
      if (this.is_air_prediction == true) {
        download_url = download_url.replace('storages', 'flowPhotos');
        this.storage = 'flowPhotos'
      }
      var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, this.device_guid);

      var path = '{0}/{1}/files'.format(this.storage, this.device_guid);
      loopback
        .get(path)
        .then(res => {
          var data = _.sortBy(res, ['atime']); // desc : .reverse()
          this.deviceImages = [];

          data.forEach(function(file) {
            self.deviceImages.push({
              url: download_url + '/' + file.name
            })
          })
          if (this.deviceImages.length == 0) {
            var network_type = this.device.network_type;
            download_url = 'img/tech9_dev.png';
            switch (network_type.toLowerCase()) {
              case 'nb-iot': case 'cat.m1': download_url = 'img/tech9_plug.png'; break;
              case 'lora': download_url = 'img/tech9_lora.jpg'; break;
            }
            this.deviceImages.push({
              url: download_url
            })
          }
        })
        .catch(err => {
          console.log('Device::getDeviceImages Error: ', err.toString())
        })
    },
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
.deviceSlideBox {
  margin-top: 20px;
  float: left;
  width: 50%;
  height: 420px;
  overflow: hidden;
  position: relative;
}
.deviceSlideBox img {
  margin: 0 auto;
  width: 100%
}
.deviceInfoBox {
  margin-top: 20px;
  margin-left: 20px;
  width: 20%;/*calc(100% - 820px);*/
  height: auto;
  overflow: hidden;
  float: left;
}

.deviceInfoBox table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.deviceInfoBox table th {
  width: 130px;
  text-align: left;
  border-bottom: 1px solid #eee;
  height: 44px;
  font-size: 14px;
  color: #999;
}

.deviceInfoBox table td {
  border-bottom: 1px solid #eee;
  height: 44px;
  font-size: 14px;
  color: #333;
}

.deviceInfoBox .btnBox {
  margin-top: 10px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.deviceInfoBox .btnBox button {
  float: left;
  width: 118px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #eee;
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}
/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 1500px) {
  .deviceInfoBox {
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
    height: auto;
    overflow: hidden;
    float: left;
  }
  .deviceSlideBox {
    margin-top: 20px;
    float: left;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
}

</style>
