<template>
  <CRow>
    <CCol col="12" xl="12">
      <ul class="nav nav-tabs nav-pills">
        <li class="nav-item">
          <a tab class="nav-link" @click.prevent="setActive('tab0')" :class="{ active: isActive('tab0') }" href='#tab0'>측정 이력</a>
        </li>
        <li class="nav-item ">
          <a tab class="nav-link" @click.prevent="setActive('tab1')" :class="{ active: isActive('tab1') }" href='#tab1'>역세척 제어</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane" id='tab0' :class="{ 'active show': isActive('tab0') }">
          <HistoryMeasure
              ref="historyMeasure"
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
          <Camera
              ref="cameraView"
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>

        <div class="tab-pane" id='tab1' :class="{ 'active show': isActive('tab1') }">
          <WashingControl
              ref="washingControl"
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
      </div>


    </CCol>
  </CRow>
</template>

<script>
import HistoryMeasure from '@/views/device/HistoryMeasure';
import WashingControl from '@/views/btfs/WashingControl';
import Camera from '@/views/btfs/Camera';

export default {
  name: 'History',
  components: {
    HistoryMeasure,
    Camera,
    WashingControl
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    device: {
      type: Object
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
  },
  data () {
    return {
      isAdmin: this.$store.state.auth.is_admin,
      activeItem: 'tab0',
    }
  },
  methods: {
  	isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      if (this.activeItem === 'tab0')
        return this.$refs.historyMeasure.getDates();
      else
        return this.$refs.historyControl.getDates();
    },   
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
</style>