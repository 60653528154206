<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Image Viewer"
    color="info"
    size="lg"
  >

    <template #header>
      <h6 class='modal-title text-center'>{{title}}</h6>
    </template>

    <div class='modal-body'>
      <img :src="image.url" alt="" style="width:100%;">
    </div>

    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>
  
  </KModal>

</template>

<script>
import loopback from '@/services/loopback';

export default {
  name: 'ImageDialog',
  data () {
    return {
      showModal: false,
      title: '생성',
      which: 'create',
      image: {
        url: '',
        create_at: new Date(),
      },
      isEditMode: false
    }
  },
  methods: {

    register() {
      this.which === 'create' ? this.createUser() : this.updateUser();
      this.showModal = false
    },
    cancel() {
      this.showModal = false
    },
    show(image) {
      this.image = image;
      this.showModal = true
    }
  }

}
</script>