<template>
  <CRow>
    <CCol col="12" xl="12">
      <Search 
          :dates.sync="dates" 
          :is_measures="false" 
          :enable_excel.sync="enable_excel"
          @search="searchStat"
          @save="saveStats()"
      />

      <CCard>
        <CCardBody col="12" xl="12">
          <img :src="device_image" style="max-width: 80%; display: block; margin: 10px auto;">
          <div class="row">
            <div class='col-sm-12 col-lg-12'>
              <CButton class="float-right mr-3" @click="openSettings('man')" color="warning">수동역세척</CButton>
              <CButton class="float-right mr-1" @click="openSettings('semi')" color="info">반자동역세척</CButton>
              <CButton class="float-right mr-1" @click="openSettings('auto')" color="success">자동역세척</CButton>
            </div>
          </div>

          <div class="row-margin"></div>

          <v-table id="historyTable" :columns="columns" :data="data" :options="options"></v-table>
        </CCardBody>
      </CCard>

      <WashingDialog
          ref="washingDialog"
      />

      <Progress
          ref="progressDialog"
          title="확인"
      />

      <Notify ref="notifyDialog"/>

    </CCol>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm';
import XLSX from 'xlsx';
import utils from '@/services/utils';

import EventBus from '@/services/EventBus'
import Search from '@/views/device/Search';

import { DATE } from '@/constants/constant'
import {
  HISTORY_TABLE_OPTIOINS, HISTORY_TABLE_COLUMS, HISTORY_TABLE_COLUMS2
} from "@/views/btfs/washSettings";

import Progress from '@/views/popups/Progress';
import WashingDialog from '@/views/btfs/WashingDialog';

export default {
  name: 'WashingControl',
  components: {
    WashingDialog,
    Search,
    Progress
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    device: {
      type: Object
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.sites = this.userInfo.sites
    utils.loadThresholds(res => this.thresholds = res)
    if (this.service_type === 'filtered') {
      this.columns = HISTORY_TABLE_COLUMS;
    } else {
      this.columns = HISTORY_TABLE_COLUMS2;
    }
    _.find(this.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
  },
  mounted() {
    var self = this;
    $('#historyTable').on('check.bs.table', function(e, row, $element) {
      self.selected_row = row;
    });
  },
  data () {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    var from = new Date(today.setTime(today.getTime() - 7 * 86400000))
    var to = new Date(now.setTime(now.getTime() + 86400000))

    // from = new Date('2019-01-01'); // test

    return {
      isAdmin: this.$store.state.auth.is_admin,
      device_image: '',
      date_type: DATE.TYPE.RAWDATA,
      dates: [from, to],
      service_type: 'submerged',
      enable_excel: false,
      options: HISTORY_TABLE_OPTIOINS,
      columns: HISTORY_TABLE_COLUMS,
      data : [],
      selected_row: null,
      thresholds: []
    }
  },
  watch: {
    device: function(new_val, old_val) {
        this.service_type = new_val.service_type;
        console.log('WashingControl::watch device[service_type] :', this.service_type)
        if (this.service_type === 'filtered') {
          this.columns = HISTORY_TABLE_COLUMS;
          this.device_image =  'images/backWashing.png';
        } else {
          this.columns = HISTORY_TABLE_COLUMS2;
          this.device_image =  'images/backWashing_v2.png';
        }
        _.find(this.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
      }
  },
  methods: {
  	isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      return this.dates;
    },
    openSettings(which) {
      console.log('WashingControl::openSettings which:', which)
      this.$refs.washingDialog.show(which, this.device);
    },
    searchStat(dates) {
      this.dates = dates;
      this.getStats();
    },
    makeFileName(name) {
      var from = moment(this.dates[0]).format('YYYY-MM-DD');
      var to = moment(this.dates[1]).format('YYYY-MM-DD');
      return name + "_" + from + "-" + to;
    },
    saveStats() {
      var self = this;
      if (_.isEmpty(this.data)) {
        this.$refs.notifyDialog.show('검색된 데이터가 없습니다.');
        return;
      }
      var site = _.find(this.sites, {guid:this.device.site_guid})

      var write = function(callback) {
        var historyData = self.data;
        var r = [];

        historyData.forEach(function(data, i) {
          var row = {};
          var keys = _.keys(data);
          keys.forEach(key => {
            var attr = _.find(self.columns, {field:key})
            if (key === 'created_at')
              row[attr.title] = moment(data[key]).format('YYYY-MM-DD HH:mm:ss')
            else
              row[attr.title] = data[key]
          })
          r.push(row);
        })

        var ws = XLSX.utils.json_to_sheet(r);

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, self.device.name);
        var filename = self.makeFileName('역세척제어-' + site.name);
        XLSX.writeFile(wb, filename + ".xlsx");
      }

      this.$refs.progressDialog.show({message: '요청 데이터를 저장중입니다.', counter:50, callback: write})
    },
    getSiteGuid() {
      var filter = {
        where: {
          guid: this.device_guid
        },
        fields: {
          site_guid: true
        }
      }
      return this.$store.dispatch('rest/find', {model:'devices',filter:filter})
        .then(device => {
          return Promise.resolve(device[0].site_guid)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    buildStatData (data, date_type, isSave) {
      var self = this
      var result = []
      var getType = function(type) {
        switch (type) {
          case 'se': case 'semi': return '반자동';
          case 'ma': case 'man':  return '수동';
          case 'au': case 'auto': return '자동';
        }
      }

      var isSave = isSave || false;
      data.forEach(function(e) {
        var row = {
          type: getType(e.data.type),
          action: e.data.action === 1 ? '운행' : '중지',
          created_at: e.created_at
        }
        if (isSave) {
          var fmt = date_type === DATE.TYPE.DAILY ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
          row.created_at = moment(e.created_at).format(fmt);
        }
        var attrs = []
        if (self.service_type === 'filtered') {
          attrs = ['drain', 'airwash', 'drain2', 'waterwash'];
        } else {
          attrs = ['bow', 'xv01', 'xv02', 'xv03', 'xv04', 'xv05', 'p01'];
        }
        for (var i = 0; i < attrs.length; i++) {
          var el = attrs[i]
          row[el] = e.data[el] === undefined ? '-' : e.data[el]
        }
        result.push(row)
      });
      return result;
    },
    getStats() {
      var where = {
        date_type: this.date_type,
        from: moment(this.dates[0]).utc().format('YYYY-MM-DD HH:mm:ss'),
        to:   moment(this.dates[1]).utc().format('YYYY-MM-DD HH:mm:ss'),
        site_guid: undefined,
        device_guid: this.device_guid,
      }
      this.enable_excel = false;
      var stat_data = undefined;
      this.getSiteGuid()
        .then(site_guid => {
          where.site_guid = site_guid
          return this.$store.dispatch('rest/method', {model:'stats',method:'getWashingHistory',data:where})
        })
        .then((res) => {
          stat_data = this.buildStatData(res.data, this.date_type)
          this.data = stat_data;
          this.enable_excel = !_.isEmpty(stat_data);
        })
        .catch((err) => {
          console.log('WashingControl::getStat error:', err.toString())
        })
    },
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
.row-margin {
  margin: 20px 0 20px;
}

</style>
