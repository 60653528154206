<template>
  <KModal
    :show.sync="autoCarmeraSet"
    :no-close-on-backdrop="true"
    :centered="true"
    title="auto Carmera Set"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">{{ title }}</h6>
    </template>

    <div class="modal-body">
      <label><strong class="text-danger">자동 사진 촬영 시간 설정</strong> </label>
      <select
        id="upload_cron"
        placeholder="시간 설정"
        class="form-control"
        v-model="cronData.cycle"
      >
      
      <option value="" disabled hidden> 시간 설정 </option>
        <option
          v-for="(i, index) in cronStrings"
          :value="i.value"
          :key="index"
        >
          {{ i.name }}
        </option>
      </select>
    </div>

    <template #footer>
      <CButton @click="confirm_()" color="info">실행</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>
    
  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
  </KModal>
  
</template>

<script>
import loopback from "@/services/loopback";

export default {
  name: "AutoCarmeraSet",
  data() {
    return {
      autoCarmeraSet: false,
      title: "사진",
      which: "create",
      device_guid: "",
      is_activation : false,
      isEditMode: false,
      cronData: {
        cycle: "",
      },
      cronStrings: [
        {
          name: "자동촬영 정지",
          value: '0',
        },      
        {
          name: "1분",
          value: '1',
        },  
        {
          name: "5분",
          value: '5',
        },
        {
          name: "10분",
          value: '10',
        },
        {
          name: "1시간",
          value: '60',
        },
        {
          name: "3시간",
          value: '180',
        },
        {
          name: "8시간",
          value: '480',
        },
      ],
    };
  },

  methods: {
    confirm_(){
      var message = "["+this.title  + "] 의 자동 사진 촬영을 하시겠습니까?"
      this.$refs.confirmDialog.show(message, '메시지', undefined, 'execute');   
    },
    hideModal(event) {
        console.log(event);
      if (event.which === 'execute') {
        if(event.data === 'confirm'){
          this.execute();
          this.autoCarmeraSet = false;
        }        
        this.autoCarmeraSet = false;
        return
      } 
    },
    cancel() {
      this.autoCarmeraSet = false;
    },
    show(guid, title) {
      this.device_guid = guid;
      this.title = title;
      this.autoCarmeraSet = true;
    },
    execute() {
      // console.log("실행");
      // console.log(this.cronData.cycle);
      // console.log(this.cronData.repeat);

      if(this.cronData.cycle == ""){
        console.log('Execute::Empty Value ')
        return;
      }

      console.log('Execute::Salt Spray guid: ', this.device_guid)

      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'shot',
          type: 'auto',
          data: "shotimage.jpg",
          time: this.cronData.cycle
        }]
      }   
      console.log('Execute::Salt Spray Data:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Execute::Salt Spray res: ', res)
        })
        .catch(err => {
          console.log('Execute::Salt Spray error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
  }
};
</script>