<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody col="12" xl="12">
          <CRow>
            <div class="col-sm-6 col-lg-6" style="padding:6px;" v-for="(view, i) in screens">
              <div style="text-align:center;color:white;background-color:#f08b28">
                <h3>{{view.title}}</h3>
              </div>

              <div v-if="!view.deviceImages.length">
                <div class="text-center border p-5">
                  <h4>측정된 이미지 파일 없음.<br/></h4>
                </div>
              </div>
              <div :id="'device-img' + view.direction" class="carousel slide border px-2" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#device-img" :data-slide-to="index" v-for="(img, index) in view.deviceImages" :class='{active:index==0}'></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item" v-for="(img, index) in view.deviceImages" :class='{active:index==0}'>
                    <img class="d-block w-100" :src="img.url" @click="openDialog(img)" alt="">
                    <div class="carousel-caption">
                      <p>{{img.label}}<br>{{img.created_at}}</p>
                    </div>
                  </div>
                </div>
                <a class="carousel-control-prev" :href="'#device-img' + view.direction" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" :href="'#device-img' + view.direction" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

              <div class="row-margin"></div>

              <div class="col-sm-6 col-lg-6" style="float:none;margin:auto;">
                <CButtonToolbar justify="center">
                  <CButton color="info" @click="takePicture(view.direction)">사진 촬영</CButton><p></p>
                </CButtonToolbar>
              </div>

              <div class="row-margin"></div>

              <div class="col-sm-6 col-lg-6" style="float:none;margin:auto;">
                <CButtonToolbar justify="between">
                  <CButton color="success" class="mr-1" @click="turnLight(view.direction, 'on')">조명 ON</CButton>
                  <CButton color="success" class="mr-1" @click="turnLight(view.direction, 'off')">조명 OFF</CButton>
                </CButtonToolbar>
              </div>

            </div>

          </CRow>
        </CCardBody>
      </CCard>

    </CCol>

    <ImageDialog ref="imageDialog"/>

    <Notify ref="notifyDialog"/>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';

import EventBus from '@/services/EventBus'
import ImageDialog from '@/views/btfs/ImageDialog'

export default {
  name: 'Camera',
  components: {
    ImageDialog
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    device: {
      type: Object
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.getDeviceImages();
    EventBus.$on('reload', this.reloadListener);
  },
  mounted() {
  },
  beforeDestroy(){
    EventBus.$off('reload', this.reloadListener)
  },
  data () {
    return {
      isAdmin: this.$store.state.auth.is_admin,
      storage: "storages",
      screens: [
        {
          title: '전처리조 사진',
          direction: 'left',
          deviceImages: [],
        },
        {
          title: '여과조 사진',
          direction: 'right',
          deviceImages: [],
        }
      ]
    }
  },
  methods: {
    getDeviceImages() {
      var self = this
      this.screens.forEach(view => {
        view.deviceImages = [];
      })

      /*****************************************************/
      var dates = this.$parent.getDates();
      /*****************************************************/
      var from = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss');
      var to = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss');

      var filter = {
        where: {
          and: [{
              device_guid: this.device_guid
            },
            {
              created_at: {
                "gte": from
              }
            },
            {
              created_at: {
                "lt": to
              }
            }
          ]
        },
        order: 'created_at desc'
      }

      this.$store.dispatch('rest/find', {model:'photos',filter:filter})
        .then(function(response) {
          var pushImage = function(info) {
            var path = '{0}/{1}s/{2}/download/{3}'.format(loopback.defaults.baseURL, info.storage, self.device_guid, info.file_name)
            var index = info.flow_type === 'in' ? 0 : 1;
            self.screens[index].deviceImages.push({
              url: path,
              created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
            })
          }
          for (var i = 0; i < response.length; i++) {
            var info = response[i]
            if (_.isUndefined(info.storage)) continue;
            // if (_.isUndefined(info.file.crop)) continue;
            pushImage(info);
          }
        })
    },
    takePicture(direction) {
      console.log('Camera::takePicture dicrection: ', direction)

      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'shot',
          type: direction.substring(0,2),
          data: "shotimage.jpg"
        }]
      }
      // console.log('Camera::takePicture params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Camera::takePicture res: ', res)
        })
        .catch(err => {
          console.log('Camera::takePicture error: ', err.toString())
          var message = err.toString();
          if(message === "Error: Already running command: run after a minutes"){
            message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
          }
          this.$refs.notifyDialog.show(message.toString())
        })

    },
    turnLight(direction, act) {
      console.log('Camera::turnLight dicrection:{0}, action:{1} '.format(direction, act))

      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'actu',
          type: 'li',
          data: {
            type: direction.substring(0,2),
            action: act === 'on' ? 1 : 0
          }
        }]
      }

      // console.log('Camera::turnLight params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Camera::turnLight res: ', res)
        })
        .catch(err => {
          console.log('Camera::turnLight error: ', err.toString())
          var message = err.toString();
          if(message === "Error: Already running command: run after a minutes"){
            message = '이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.';
          }
          this.$refs.notifyDialog.show(message)
        })
    },
    openDialog(img) {
      this.$refs.imageDialog.show(img)
    },
    reloadListener() {
      var dates = this.$parent.getDates();
      console.log('Camera::reloadListener dates:', dates)
      this.getDeviceImages();
    },
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
.row-margin {
  margin: 10px 0 10px;
}

</style>