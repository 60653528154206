<template>
  <KModal
    :show.sync="sensorMappingSet"
    :no-close-on-backdrop="true"
    :centered="true"
    title="sensor Mapping Set"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">{{ title }}</h6>
    </template>

    <div class="modal-body">
      <label><strong class="text-danger">센서 값 맵핑 설정</strong> </label>
        <div class="row">
        </div>
        <div class="search-margin"></div>
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <label>센서 측정 최소값</label>
            <input type='number' id='set_in_min' class='form-control' placeholder='0.0' v-model='standard.in_min' :step='.1'>
          </div>
          <div class="col-sm-6 col-lg-6">
            <label>센서 측정 최대값</label>
            <input type='number' id='set_in_max' class='form-control' placeholder='0.0' v-model='standard.in_max' :step='.1'>
          </div>
        </div>

        <div class="search-margin"></div>
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <label>출력 최소값</label>
            <input type='number' id='set_out_min' class='form-control' placeholder='0.0' v-model='standard.out_min' :step='.1'>
          </div>     
          <div class="col-sm-6 col-lg-6">
            <label>출력 최대값</label>
            <input type='number' id='set_out_max' class='form-control' placeholder='0.0' v-model='standard.out_max' :step='.1'>
          </div>        
        </div>
    </div>

    <template #footer>
      <CButton @click="confirm_()" color="info">실행</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>
    
  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
  </KModal>
  
</template>

<script>
import loopback from "@/services/loopback";

export default {
  name: "SensorMappingSet",
  data() {
    return {
      sensorMappingSet: false,
      title: "맵핑",
      which: "create",
      device_guid: "",
      is_activation : false,
      isEditMode: false,
      standard: {
        in_min: 0.0,
        in_max: 0.0,
        out_min: 0.0,
        out_max: 0.0
      }
    };
  },

  methods: {
    confirm_(){
      var message = "["+this.title  + "] 의 맵핑 설정을 하시겠습니까?"
      this.$refs.confirmDialog.show(message, '메시지', undefined, 'execute');   
    },
    hideModal(event) {
        console.log(event);
      if (event.which === 'execute') {
        if(event.data === 'confirm'){
          this.execute();
          this.sensorMappingSet = false;
        }        
        this.sensorMappingSet = false;
        return
      } 
    },
    cancel() {
      this.sensorMappingSet = false;
    },
    show(guid, title, standard) {
      this.device_guid = guid;
      this.title = title;
      this.standard = standard;
      this.sensorMappingSet = true;
      console.log(this.standard);
    },
    execute() {
      // console.log("실행");
      // console.log(this.cronData.cycle);
      // console.log(this.cronData.repeat);

      if(this.standard.in_max <= 0 || this.standard.out_max <= 0){
        console.log('Execute::Empty Value ')
        return;
      }

      console.log('Execute::Salt Spray guid: ', this.device_guid)

      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'calc',
          type: 'wl', 
          data: {     
            standard: this.standard
          }
        }]
      }   
      console.log('Execute::Salt Spray Data:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Execute::Salt Spray res: ', res)
        })
        .catch(err => {
          console.log('Execute::Salt Spray error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })

      var update_data = {
        standard: this.standard
      }
      var where = {
        guid: this.device_guid
      }
      this.$store.dispatch('rest/upsertWithWhere', {model:'devices', where:where, data:update_data})
        .then(res => {
          // console.log('DeviceInfo upsert OK: ', res)
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: res
          }
          this.$emit('update', msg)
          this.sensorMappingSet = false
        })
        .catch(err => {
          console.log('DeviceInfo upsert Err: ', err.toString())          
          this.$refs.notifyDialog.show(err.toString())
          this.sensorMappingSet = false
        })      
    },
  }
};
</script>