const WASHING_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    striped: false,
    bordered: false,
    pagination: false,
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: false,
    showToggle: false
}

const WASHING_TABLE_COLUMS = [
    {
        field: 'item',
        title: '항목',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'time',
        title: '시간(초)',
        align: 'center',
        valign: 'top',
        sortable: false
    }, {
        field: 'enable',
        title: '설정',
        align: 'center',
        valign: 'top',
        sortable: false,
    }
]

const HISTORY_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    striped: false,
    bordered: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    pagination: true,
    paginationinfo: false,
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: false,
    showToggle: false,
    sortName: 'created_at',
    sortOrder: 'desc',
    uniqueId: 'guid'
}

const HISTORY_TABLE_COLUMS = [
    {
        field: 'guid',
        title: 'guid',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false,
    }, {
        field: 'created_at',
        title: '역세척 일자',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'type',
        title: '타입',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'action',
        title: '동작',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'drain',
        title: '정체수배출',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'airwash',
        title: '공기 세척',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'drain2',
        title: '역세척폐수배수',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'waterwash',
        title: '처리수이송',
        align: 'center',
        valign: 'top',
        sortable: false,
    }
]

const HISTORY_TABLE_COLUMS2 = [
    {
        field: 'guid',
        title: 'guid',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false,
    }, {
        field: 'created_at',
        title: '역세척 일자',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'type',
        title: '타입',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'action',
        title: '동작',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'bow',
        title: '링블로어',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'xv01',
        title: 'XV01',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'xv02',
        title: 'XV02',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'xv03',
        title: 'XV03',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'xv04',
        title: 'XV04',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'xv05',
        title: 'XV05',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'p01',
        title: '펌프',
        align: 'center',
        valign: 'top',
        sortable: false,
    }
]

const WASHING_TYPE = {
    'filtered' : [
        {
            item: '정체수 배출',
            field: 'drain',
            time: 30,
            enable: false
        }, {
            item: '공기 세척',
            field: 'airwash',
            time: 30,
            enable: false
        }, {
            item: '역세척폐수배수',
            field: 'drain2',
            time: 10,
            enable: false
        }, {
            item: '처리수이송',
            field: 'waterwash',
            time: 10,
            enable: false
        }
    ],
    'submerged': [
        {
            item: '링블로어',
            field: 'bow',
            time: 10,
            enable: false
        }, {
            item: 'XV01',
            field: 'xv01',
            time: 10,
            enable: false
        }, {
            item: 'XV02',
            field: 'xv02',
            time: 10,
            enable: false
        }, {
            item: 'XV03',
            field: 'xv03',
            time: 10,
            enable: false
        }, {
            item: 'XV04',
            field: 'xv04',
            time: 10,
            enable: false
        }, {
            item: 'XV05',
            field: 'xv05',
            time: 10,
            enable: false
        }, {
            item: '펌프',
            field: 'p01',
            time: 10,
            enable: false
        }
    ]
}

export {
    WASHING_TABLE_OPTIOINS,
    WASHING_TABLE_COLUMS,
    HISTORY_TABLE_OPTIOINS,
    HISTORY_TABLE_COLUMS,
    HISTORY_TABLE_COLUMS2,
    WASHING_TYPE
}