<template>
  <div>
    <Device 
      :device_guid="this.$route.params.id" 
      :device.sync="device" 
      @update="onUpdated"
      @reset="onResetSensor"/>

    <History ref="historyView" :device_guid="this.$route.params.id" :device.sync="device"/>
    
    <Event ref="eventView" :device_guid="this.$route.params.id"/>
  </div>
</template>

<script>
import Device from '@/views/btfs/Device'
import Event from '@/views/device/Event'
import History from '@/views/btfs/History'

export default {
  name: 'DeviceManage',
  components: {
    Device,
    Event,
    History
  },
  created: function() {
    // console.log('DeviceManage params: ', this.$route.params)
    this.getDeviceInfo();
  },
  data () {
    return {
      device_guid: this.$route.params.id,
      device: {
        name:'tech9'
      }
    }
  },
  methods: {
    onUpdated(event) {
      console.log('DeviceManage::onUpdated[updated event was changed from child view] : ', event)
      // this.getDeviceInfo(event.data.guid)
      this.device = event.data;
      // this.device = event;
    },
    onResetSensor(event) {
      console.log('DeviceManage::onResetSensor[deleted all sensor data]')
      // reload info of the device
      this.getDeviceInfo(this.device_guid)
    },
    getDeviceInfo(device_guid) {
      this.$store.dispatch('rest/findById', {model:'devices',guid:this.device_guid})
        .then(device => this.device = device)
    },
    /*****************************************************/
    getSearchDates() {
      return this.$refs.historyView.getDates();
    }
    /*****************************************************/
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
</style>